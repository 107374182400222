import React from 'react';

import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';

import { PortalLayout } from 'front-components/src/components';

import { AdminMenu } from '../../components/asside-menu/asside-menu';
import { ResellerDetailsContent } from '../../content/reseller/reseller-details';
import { PortalHeader } from '../../components/portal-header/portal-header';
import { EditResellerContent } from '../../content/reseller/edit-reseller';

function ResellerDetailsPage({ location, params }) {
  const asside = <AdminMenu />;

  const content = location.state.isEdit ? (
    <EditResellerContent resellerId={params.id} />
  ) : (
    <ResellerDetailsContent resellerId={params.id} />
  );

  return (
    <main>
      <title>Resellers Page</title>
      <PortalLayout header={<PortalHeader />} asside={asside}>
        {content}
      </PortalLayout>
    </main>
  );
}

export default ResellerDetailsPage;
