import React, { useState, useEffect } from 'react';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import { useQuery, useMutation } from 'react-query';
import { resellerService } from '@myneva-portals/services/src/services';
import { navigate } from 'gatsby';
import { updateResellerDetails } from '../../state/reducers/reseller-details';
import { useDispatch, useSelector } from 'react-redux';
import {
  defaultErrorToast,
  redirectErrorToast,
} from '../../components/sections/static-data/default-error-toast';
import { ResellerDetails } from '@myneva-portals/projects/src/components';
import {
  LoadingWrapper,
  showToast,
  ErrorTimeout,
} from 'front-components/src/components';
import { useTranslation } from 'react-i18next';
import IResellerDetails from '@myneva-portals/models/src/models/reseller-details';

export interface ResellerDetailsContentProps {
  resellerId: number;
}

export const ResellerDetailsContent: React.FC<ResellerDetailsContentProps> = (
  props: ResellerDetailsContentProps
) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const redirectToastConfig = redirectErrorToast();

  const { breadcrumbs } = useSelector(navigationSelector);

  const [isSessionError, setIsSessionError] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [customBreadcrumb, setCustomBreadcrumb] = useState(
    breadcrumbsUtils.getBreadcrumbs(breadcrumbs)
  );

  const { isLoading, error, data, refetch } = useQuery(
    'getResellersDetailsQuery',
    () =>
      resellerService
        .getResellerDetails(props.resellerId)
        .then((data: IResellerDetails) => data),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  useEffect(() => {
    if (data) {
      dispatch(updateResellerDetails(data));
      const customBreadCrumbConfig = [
        {
          breadcrumbName: data.reseller.General.companyName,
          path: '/resellers/' + data.reseller.General.id,
        },
      ];
      setCustomBreadcrumb(
        breadcrumbsUtils.getBreadcrumbs(breadcrumbs, customBreadCrumbConfig)
      );
    }
  }, [data]);

  const handleDeleteDialogClose = (isDialogOpen) => {
    setOpenDeleteDialog(isDialogOpen);
  };

  const { mutate: deleteResellerMutation } = useMutation(
    () =>
      resellerService
        .deleteReseller(props.resellerId)
        .then(() => handleDeleteDialogClose(false))
        .then(() => navigate('/resellers')),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const deleteReseller = async () => {
    deleteResellerMutation();
  };

  const handleEdit = () => {
    navigate(`/resellers/${props.resellerId}`, { state: { isEdit: true } });
  };

  const content = (
    <ResellerDetails
      data={data}
      translation={t}
      customBreadcrumb={customBreadcrumb}
      isDialogOpen={openDeleteDialog}
      handleEdit={handleEdit}
      handleDeleteDialogClose={handleDeleteDialogClose}
      deleteReseller={deleteReseller}
    />
  );

  const errorComponent = (
    <ErrorTimeout
      header={t('TIMEOUT_ERROR')}
      text={t('TIMEOUT_ERROR_MSG')}
      buttonLabel={t('REFETCH_BUTTON_LABEL')}
      onButtonClick={() => refetch()}
    />
  );

  return (
    <div>
      <LoadingWrapper
        isLoading={isLoading || isSessionError}
        content={error ? errorComponent : content}
        useLineProgress={true}
      />
    </div>
  );
};
